import { useEffect, useState } from 'react';
import ModalWidnow from '../UI/ModalWidnow/ModalWidnow';
import Loader from '../UI/Loader/Loader';
import s from './UserInfoModalWindow.module.css';
import { getPhotoUser, getUserById } from '../../api/graph';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import mailIcon from '../../assets/img/icons/mail-gray-icon-1.svg';
import commentIcon from '../../assets/img/icons/comment-gray-icon.svg';
import phoneIcon from '../../assets/img/icons/call-outgoing-icon.svg';
import cakeIcon from '../../assets/img/icons/cake-birthday-icon.svg';
import userIcon from '../../assets/img/icons/fi-rs-user.svg';
import chartIcon from '../../assets/img/icons/chart-tree-icon.svg';
import { dateConverter } from '../../helpers/dateConverter';

const UserInfoModalWindow = ({ userId, visibleStatus, closeModal }) => {
	const { instance, accounts } = useMsal();
	let [userData, setUserData] = useState(false);
	let [userImg, setUserImg] = useState('');
	let [isFetching, setIsFetching] = useState(false);

	function RequestUsersData(id) {
		setIsFetching(true);
		instance
			.acquireTokenSilent({
				scopes: ['User.Read.All'],
				account: accounts[0],
			})
			.then((response) => {
				getUserById(response.accessToken, id)
					.then((response) => response.json())
					.then((result) => {
						setIsFetching(false);
						setUserData(result);
						console.log(result);
					})
					.catch((rerror) => {
						setIsFetching(false);
					});
			});

		setTimeout(() => {
			setIsFetching(false);
		}, 12000);
	}

	function RequestProfilePhoto(id) {
		return instance
			.acquireTokenSilent({
				...loginRequest,
				account: accounts[0],
			})
			.then((response) => {
				return getPhotoUser(response.accessToken, id)
					.then((response) => {
						if (response) {
							return response.blob();
						} else {
							return '';
						}
					})
					.then((result) => {
						const url = window.URL || window.webkitURL;
						const blobUrl = url.createObjectURL(result);

						if (result.type === 'application/json') {
							return '';
						}

						setUserImg(blobUrl);
					})
					.catch((error) => console.log(error));
			});
	}

	useEffect(() => {
		console.log(userId);
		RequestUsersData(userId);
		RequestProfilePhoto(userId);
	}, [userId]);

	return (
		<>
			{visibleStatus && (
				<ModalWidnow closeModal={closeModal}>
					{isFetching ? (
						<Loader />
					) : (
						<>
							{userData && (
								<div className={s.info_container}>
									<div className={s.user_row}>
										<img className={s.user_img} src={userImg} alt="" />
										<div>
											<p className={s.user_title}>{userData.displayName}</p>
											<p className={s.user_job}>{userData.jobTitle}</p>
											<p className={s.user_job}>{userData.department}</p>
										</div>
									</div>
									<div className={s.user_contacts}>
										<p className={s.contacts_title}>Контактні данні</p>
										<ul className={s.contacts_list}>
											<li className={s.contacts_item}>
												<img src={mailIcon} alt="" />
												<a
													href={`mailto:${userData.userPrincipalName ? userData.userPrincipalName : ''}`}
													target="_blank"
													className={s.contacts_content}>
													<p>Електронна пошта</p>
													<p className={s.blue}>{userData.userPrincipalName ? userData.userPrincipalName : 'не вказано'}</p>
												</a>
											</li>
											<li className={s.contacts_item}>
												<img src={commentIcon} alt="" />
												<a
													href={`https://teams.microsoft.com/l/chat/0/0?users=${
														userData.userPrincipalName ? userData.userPrincipalName : ''
													}`}
													target="_blank"
													className={s.contacts_content}>
													<p>Чат Teams</p>
													<p className={s.blue}>{userData.userPrincipalName ? userData.userPrincipalName : 'не вказано'}</p>
												</a>
											</li>
											<li className={s.contacts_item}>
												<img src={phoneIcon} alt="" />
												<a
													href={`tel:${userData.mobilePhone ? userData.mobilePhone.replace(/[^+\d]/g, '') : ''}`}
													target="_blank"
													className={s.contacts_content}>
													<p>Мобільний</p>
													<p className={s.blue}>{userData.mobilePhone ? userData.mobilePhone : 'не вказано'}</p>
												</a>
											</li>
											<li className={s.contacts_item}>
												<img src={cakeIcon} alt="" />
												<div className={s.contacts_content}>
													<p>День народження</p>
													<p>
														{userData.onPremisesExtensionAttributes.extensionAttribute2
															? dateConverter(userData.onPremisesExtensionAttributes.extensionAttribute2)
															: 'не вказано'}
													</p>
												</div>
											</li>
											<li className={s.contacts_item}>
												<img src={userIcon} alt="" />
												<div className={s.contacts_content}>
													<p>Посада</p>
													<p>{userData.jobTitle ? userData.jobTitle : 'не вказано'}</p>
												</div>
											</li>
											<li className={s.contacts_item}>
												<img src={chartIcon} alt="" />
												<div className={s.contacts_content}>
													<p>Відділ</p>
													<p>{userData.department ? userData.department : 'не вказано'}</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
							)}
						</>
					)}
				</ModalWidnow>
			)}
		</>
	);
};

export default UserInfoModalWindow;
